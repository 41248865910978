import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  //   baseURL: 'http://localhost:8000/',
  withCredentials: false, // This is the default
  timeout: 60000
});

export const services = {
  async getCurrentPeriods() {
    const response = await apiClient.post('/announce', { lottoMaster: 1 });
    return response;
  },
  async getPeriodsShow() {
    const response = await apiClient.post('/announces', { lottoMaster: 1 });
    return response;
  },
  async getPeriods(date: string, type: string, lottoMaster: string) {
    const period = await apiClient.get('/period?announce=' + date + '&type=' + type + '&lottoMaster=' + lottoMaster);
    return period;
  },
  async getStatisticToday() {
    const statisticToday = await apiClient.post('/announce', { lottoMaster: 1 });
    return statisticToday;
  },
  async getStatistics() {
    const statistics = await apiClient.post('/announces', { lottoMaster: 1 });
    return statistics;
  }
};